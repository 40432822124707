<template>
  <div class="px-40 border border-EEEEEE py-35 purseBox">
    <!-- 标签 -->
    <div class="flex align-center justify-between pb-25">
      <div class="font-20 text-333333">我的抽奖券</div>
      <div class="font-12 text-666666 flex align-center">
        目前所在：<el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/myIndex' }">个人中心</el-breadcrumb-item>
          <el-breadcrumb-item>我的抽奖券</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 内容 -->
    <div class="flex lottery">
      <!-- 抽奖 -->
      <div style="display: flex; justify-content: center" class="lottery1">
        <!-- <lottery-list
          ref="lottery1"
          @onend="onend"
          :list="list"
          @onsubmit="request('lottery1')"
        ></lottery-list> -->
        <lattice-lottery-cope
          ref="lottery1"
          @onend="onend"
          :list="list"
          @onsubmit="request('lottery1')"
        ></lattice-lottery-cope>
        <!-- 抽奖说明 -->
        <div class="Drawthat" @click="DrawthatBnt">抽奖说明</div>
        <div class="DrawNum">
          {{ DrawNum }}
        </div>
        <!-- 中奖明细 -->
        <div class="zhongjiangminxi cursorp" @click="dialogVisible4 = true">
          <img src="@/assets/img/zhongjiangminxi2.png" alt="" />
        </div>
        <!-- 中奖公告 -->
        <div class="zhongjiangminxi2 cursorp" @click="dialogVisible5 = true">
          <img src="@/assets/img/zhongjiangminxi.png" alt="" />
        </div>
      </div>
      <!-- 中奖名单 -->
      <div class="zjorder">
        <div class="flex align-center justify-between px-20 py-10"></div>
        <div class="flex align-center justify-center">
          <!-- <img
            src="@/assets/img/zjorder.png"
            width="16.1875rem"
            height="2.8125rem"
            alt=""
          /> -->
          <el-image
            style="width: 16.1875rem; height: 2.8125rem"
            :src="require('@/assets/img/zjorder.png')"
            fit="cover"
          ></el-image>
        </div>
        <div class="flex justify-center">
          <div class="lottery_box2" v-if="prize && prize.length">
            <Scrollinform2 :informList="prize"></Scrollinform2>
          </div>
        </div>
      </div>
    </div>
    <!-- 抽奖说明 -->
    <el-dialog :visible.sync="dialogVisible3" width="30%" title="抽奖说明" center>
      <div class="" v-html="Drawthattext"></div>
    </el-dialog>
    <!-- 中奖明细 -->
    <el-dialog :visible.sync="dialogVisible4" width="30%" title="中奖明细" center>
      <ul
        class="infinite-list minxi"
        v-infinite-scroll="load"
        style="overflow: auto"
        :infinite-scroll-immediate="false"
      >
        <li v-for="(item, index) in list2" :key="index" class="infinite-list-item">
          <div class="minxi_date">
            {{ item.date_time }}
          </div>
          <div class="minxi_ul">
            <div
              v-for="(list, indexs) in item.list"
              :key="indexs"
              class="minxi_li flex align-center justify-between"
            >
              <div>
                <div class="mb-15 text-26252D">
                  {{ list.title }}
                </div>
                <div class="font-14 text-5E5D65">
                  {{ list.create_time }}
                </div>
              </div>
              <div class="text-FF6253 font-18">
                <span v-if="list.plus_reduce == 1">+</span><span v-else>-</span>
                {{ list.balance }}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </el-dialog>
    <!-- 中奖公告 -->
    <el-dialog :visible.sync="dialogVisible5" width="30%" title="中奖公告" center>
      <ul
        class="infinite-list minxi2"
        v-infinite-scroll="load2"
        style="overflow: auto"
        :infinite-scroll-immediate="false"
      >
        <li v-for="(item, index) in list3" :key="index" class="minxi2_li flex align-center justify-between infinite-list-item">
          <div class="">
            {{ item.create_time }}
          </div>
          <div class="">
            {{ item.user_id_name }}
          </div>
          <div class="">
            {{ item.name }}
          </div>
        </li>
      </ul>
    </el-dialog>
  </div>
</template>

<script>
import Scrollinform2 from "../../../components/Scrollinform2.vue";
import latticeLotteryCope from "@/components/lattice-lottery-cope/packages/Lottery/src/lottery-list";

import req from "../../../utils/req";
export default {
  components: {
    Scrollinform2,
    latticeLotteryCope,
  },
  data() {
    return {
      dialogVisible3: false,
      Drawthattext: "",
      prize: [],
      DrawNum: 0,
      list: [],
      list2: [],
      list3: [],
      dialogVisible4: false,
      dialogVisible5: false,
      page2: 1,
      page3: 1,
    };
  },
  mounted() {
    this.drawSet();
    this.getdrawSetList();
    this.page2 = 1;
    this.getmyLog();
    this.page3 = 1;
    this.getmyLog2();
    this.getsurplusNums();
  },
  methods: {
    request(name) {
      //模拟抽奖请求
      let that = this;
      if (!that.DrawNum || that.DrawNum == 0) {
        that.$message.error("你当天的抽奖次数已用完~");
        return;
      }
      req
        .post("index/doDraw", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          if (that.list && that.list.length) {
            that.list.forEach((item, index) => {
              if (res.data.id == item.id) {
                this.$refs[name].go(index);
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
      // setTimeout(() => {
      //   let luckyIndex = this.rndNum(0, 7);
      //   console.log(luckyIndex);
      //   this.$refs[name].go(luckyIndex);
      // }, 100);
    },
    getsurplusNums() {
      let that = this;
      let token = localStorage.getItem("token");
      if (token) {
        req
          .post("index/surplusNums", {})
          .then((res) => {
            if (res.code != 200) {
              // that.$message.error(res.msg);
              return;
            }
            that.DrawNum = res.data;
          })
          .catch((err) => {
            console.log(err);
            // that.$message.error(err.msg);
          });
      }
    },
    load() {
      this.page2 = this.page2 + 1;
      this.getmyLog();
    },
    load2() {
      this.page3 = this.page3 + 1;
      this.getmyLog2();
    },
    getmyLog2() {
      let that = this;
      req
        .post("Original/prizelist", {
          page: this.page3,
        })
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          if (this.page3 == 1) {
            that.list3 = res.data.data;
          } else {
            if (res.data.data && res.data.data.length) {
              that.list3 = [...that.list3, ...res.data.data];
            } else {
              // that.$message.error("暂无更多数据");
            }
          }
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
    getmyLog() {
      let that = this;
      req
        .post("center/myLog", {
          type: 4,
          page: this.page2,
        })
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          if (this.page2 == 1) {
            that.list2 = res.data.data.list;
          } else {
            if (res.data.data && res.data.data.list.length) {
              that.list2 = [...that.list2, ...res.data.data.list];
            } else {
              // that.$message.error("暂无更多数据");
            }
          }
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
    // 抽奖说明
    DrawthatBnt() {
      // this.dialogVisible2 = false;
      this.dialogVisible3 = true;
    },
    //抽奖动画结束
    onend(data) {
      let that = this;
      that.dialogVisible2 = false;
      req
        .post("index/addDraw", {
          id: data.id,
        })
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          that.page2 = 1;
          that.getdrawSetList();
          that.drawSet();
          that.getmyLog();
          that.page3 = 1;
          that.getmyLog2();
          that.$router.go(0);
          alert("恭喜您获得：" + data.name);
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
      console.log("抽奖结果回调：", data);
    },
    getdrawSetList() {
      let that = this;
      req
        .post("index/drawGoods", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          that.list = res.data;
          console.log("list", that.list);
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
    drawSet() {
      let that = this;
      req
        .post("index/drawSet", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          that.prize = res.data.prize;
          that.Drawthattext = res.data.prize_content;
          that.is_open = res.data.is_open;
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
  },
};
</script>

<style lang="scss">
.lottery {
  .zhongjiangminxi {
    width: 5.25rem;
    height: 2.75rem;
    img {
      width: 100%;
      height: 100%;
    }
    position: absolute;
    top: 2%;
    right: 0;
  }
  .zhongjiangminxi2 {
    width: 5.25rem;
    height: 2.75rem;
    img {
      width: 100%;
      height: 100%;
    }
    position: absolute;
    top: 10%;
    right: 0;
  }
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-dialog {
    width: fit-content;
  }
  .zjorder {
    // width: 23.6875rem;
    width: 29.6875rem;
  }
}
.minxi2 {
  height: 30rem;
  padding: 1.75rem 1.375rem 0.3125rem;

  &_li {
    margin-bottom: 2.5rem;
  }
}
.minxi {
  height: 30rem;
  &_date {
    padding: 1.375rem 1.125rem 1rem;
    background-color: #f2f2f2;
    color: #26252d;
    font-weight: bold;
  }
  &_ul {
    padding: 1.75rem 1.375rem 0.3125rem;
  }
  &_li {
    margin-bottom: 2.5rem;
  }
}
/* 为了防止样式重叠 建议给组件加上自定义的class类名 */
.lottery1 {
  width: 23rem;
  height: 36.75rem;
  background-image: url("../../../assets/img/Lotterybg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  // 抽奖说明
  .Drawthat {
    position: absolute;
    top: 20%;
    right: 0;
    width: 4.875rem;
    height: 1.8125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    border-radius: 0.5625rem 0 0 0.5625rem;
    cursor: pointer;
  }
  //抽奖次数
  .DrawNum {
    position: absolute;
    top: 29%;
    right: 34%;
    font-size: 1.0625rem;
    color: #ffffff;
  }
  /* 奖品列表的蒙层，根据抽奖动画选中的位置被显示出来 */
  .prize__item__mask {
    background-color: rgba(0, 0, 0, 0.3);
  }
  /* 奖品图样式 */
  .prize__item__image {
    width: 2.5625rem;
    height: 2.25rem;
  }
  /* 九宫格组件样式 为了跟随羡慕的自适应方案 */
  .lattice__lottery__box {
    margin: 0 auto;
    margin-top: 13rem;
    width: 16.25rem;
  }
  /* 奖品块样式 为了跟随羡慕的自适应方案 */
  .lattice__lottery__item {
    width: 5rem;
    height: 5rem;
    margin-bottom: 0.4375rem;
    border: 0;
    font-size: 0.75rem;
    color: #ff4b0d;
  }
  /* 抽奖按钮 */
  .lattice__lottery__btn {
    width: 13.1875rem;
    height: 4.1875rem;
    background-image: url("../../../assets/img/Lotterybtn.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border: 0;
    color: transparent;
    /* lottery-list 组件的抽奖按钮 */
    .list {
      margin-top: 1.875rem;
    }
  }
  /* 抽奖动画处于选中状态的奖品块样式 */
  ::v-deep .lattice__lottery__actItem {
    background-color: rebeccapurple;
    color: white;
  }
  .el-dialog__title {
    font-size: 1.25rem;
    color: #333333;
  }
}
</style>
